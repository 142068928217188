import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Typography, Box, IconButton, } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Star, StarBorder, ThumbUp, ThumbDown } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TextArea } from "./Common/styles";
import DialogModal from "./Common/DialogModal";
import { CaptureConsumerEngagementFeedbacks } from "../graphql/mutation";
import { CustomerEngagementProps } from "../types";

const CustomerEngagement = ({ customerScanDetails, sessionId, traceItemID }: CustomerEngagementProps) => {
    const [rating, setRating] = useState<number>(0);
    const [thumbs, setThumbs] = useState<string | null>(null);
    const [comment, setComment] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [thankYouMessage, setThankYouMessage] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    useEffect(() => {
        const sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
        const feedbackSubmitted = sessionData[traceItemID]?.feedbackSubmitted;
        if (feedbackSubmitted) {
            setIsSubmitted(true);
        }
    }, [sessionId, traceItemID]);

    // Feedback mutation
    const [CreateConsumerEngagement] = useMutation(CaptureConsumerEngagementFeedbacks);

    const handleStarClick = (index) => {
        setRating(index + 1);
        setError("");
    };

    const handleThumbClick = (value) => {
        setThumbs(value);
        setError("");
    };

    const handleCommentChange = (event) => {
        if (event.target.value.length > 100) {
            setError("Comments should not exceed 100 characters.");
        } else {
            setError("");
            setComment(event.target.value);
        }
    };

    const handleSubmit = () => {
        if (customerScanDetails.commentsFiled === "mandatoryOnLowRating") {
            if ((rating > 0 && rating <= 2 && !comment) || (thumbs === 'thumbsdown' && !comment)) {
                setError("Please tell us more.");
                return;
            }
        }
        setError("");

        const inputData: {
            sessionId: any;
            consumerEngagementDefId: any;
            rating: string;
            comments?: string;
        } = {
            sessionId: sessionId,
            consumerEngagementDefId: customerScanDetails.id,
            rating: customerScanDetails.feedbackMethod === "rating" ? rating.toString() : thumbs || "",
        };
        if (comment) {
            inputData.comments = comment;
        }
        setLoading(true);

        CreateConsumerEngagement({
            variables: {
                consumerEngagementFeedbackInput: inputData
            }
        })
            .then(response => {
                setLoading(false);
                setRating(0);
                setThumbs(null);
                setComment("");

                let thankYouMessage = "";
                if (customerScanDetails.feedbackMethod === "rating") {
                    if (rating >= 1 && rating <= 2) {
                        const message = customerScanDetails.submissionDisplayMessage.find(msg => msg.key === "display1to2Star");
                        thankYouMessage = message ? message.value : "";
                    } else if (rating === 3) {
                        const message = customerScanDetails.submissionDisplayMessage.find(msg => msg.key === "display3Star");
                        thankYouMessage = message ? message.value : "";
                    } else if (rating >= 4 && rating <= 5) {
                        const message = customerScanDetails.submissionDisplayMessage.find(msg => msg.key === "display4to5Star");
                        thankYouMessage = message ? message.value : "";
                    }
                } else if (customerScanDetails.feedbackMethod === "thumbs") {
                    if (thumbs === 'thumbsup') {
                        const message = customerScanDetails.submissionDisplayMessage.find(msg => msg.key === "display4to5Star");
                        thankYouMessage = message ? message.value : "";
                    } else if (thumbs === 'thumbsdown') {
                        const message = customerScanDetails.submissionDisplayMessage.find(msg => msg.key === "display1to2Star");
                        thankYouMessage = message ? message.value : "";
                    }
                } else {
                    thankYouMessage = "Thank you for your feedbacks!";
                }

                setOpen(true);
                setThankYouMessage(thankYouMessage);

                const sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
                if (sessionData[traceItemID]) {
                    sessionData[traceItemID].feedbackSubmitted = true;
                    localStorage.setItem("sessionData", JSON.stringify(sessionData));
                }
                setIsSubmitted(true);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    return (
        <>
            {!isSubmitted &&
                <Box>
                    <Typography variant="h5" sx={{ mt: 2, textAlign: "center" }}>We love your feedback</Typography>
                    {customerScanDetails.feedbackMethod === "rating" &&
                        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
                            {[...Array(5)].map((_, index) => (
                                <IconButton key={index} onClick={() => handleStarClick(index)} sx={{ color: index < rating ? 'rgb(25, 118, 210)' : 'default' }}>
                                    {index < rating ? <Star /> : <StarBorder />}
                                </IconButton>
                            ))}
                        </Box>
                    }
                    {customerScanDetails.feedbackMethod === "thumbs" &&
                        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
                            <IconButton onClick={() => handleThumbClick('thumbsup')} sx={{ color: thumbs === 'thumbsup' ? 'rgb(25, 118, 210)' : 'default' }}>
                                <ThumbUp />
                            </IconButton>
                            <IconButton onClick={() => handleThumbClick('thumbsdown')} sx={{ color: thumbs === 'thumbsdown' ? 'rgb(25, 118, 210)' : 'default' }}>
                                <ThumbDown />
                            </IconButton>
                        </Box>
                    }
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextArea
                            minRows={3}
                            placeholder={`Please tell us more`}
                            name="comments"
                            value={comment}
                            onChange={handleCommentChange}
                            sx={{ width: "300px" }}
                        />
                        {error && <Typography color="error" sx={{ mt: 1 }}>{error}</Typography>}
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            sx={{
                                background: "rgb(25, 118, 210)",
                                color: "#fff",
                                mt: 1,
                                '&:hover': {
                                    background: "rgb(25, 118, 210)"
                                },
                                '&:focus': {
                                    background: "rgb(25, 118, 210)"
                                }
                            }}
                            onClick={handleSubmit}
                            disabled={!!error || (customerScanDetails?.feedbackMethod === "rating" && rating === 0) || (customerScanDetails?.feedbackMethod === "thumbs" && thumbs === null)}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            }
            {isSubmitted &&
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" >
                        Thank you for your feedback!
                    </Typography>
                    <CheckCircleIcon sx={{ color: 'green', fontSize: 25, ml: 1 }} />
                </Box>}
            <DialogModal open={open} setOpen={setOpen} message={thankYouMessage} title="Thank you for your feedback!" />
        </>
    );
}

export default CustomerEngagement;