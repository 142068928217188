import { v4 as uuidv4 } from "uuid";
import { Certificate, DocumentLevel, FlattenedCertificate } from "./types";

export const getCertificatesForProductOrLocation = (productName: string, location: string, certificates: Certificate[]) => {
    const certificatesForItem: Certificate[] = [];
    for (let i = 0; i < certificates.length; i += 1) {
        const { documentLevel, entities } = certificates[i];
        if (documentLevel === DocumentLevel.LOCATION) {
            if (entities.some(entity => entity === location)) {
                certificatesForItem.push(certificates[i]);
            }
        } else if (documentLevel === DocumentLevel.PRODUCT) {
            if (entities.some(entity => entity === productName)) {
                certificatesForItem.push(certificates[i]);
            }
        }
    }
    return certificatesForItem;
}

export const getCertificatesForOrgInfo = (certificates: Certificate[]) => {
    const certificatesForItem: Certificate[] = [];
    for (let i = 0; i < certificates.length; i += 1) {
        const { documentLevel } = certificates[i];
        if (documentLevel === DocumentLevel.ORGANIZATION) {
            certificatesForItem.push(certificates[i]);
        }
    }
    return certificatesForItem;
}

export const flattenCertificates = (
    certificates: Certificate[]
): FlattenedCertificate[] => {
    return certificates.flatMap(cert =>
        cert.documentPaths.map(doc => ({
            certificateName: cert.certificateName,
            documentLevel: cert.documentLevel,
            entities: cert.entities,
            createdEpoch: cert.createdEpoch,
            id: doc.id,
            url: doc.url,
            fileType: doc.fileType,
        }))
    );
};

export function generateSessionId() {
    return uuidv4();
}

export function storeSessionId(traceItemId: string, sessionId: string) {
    const now = new Date();
    const expiryTime = now.getTime() + 24 * 60 * 60 * 1000; // 24 hours
    // const expiryTime = now.getTime() + 3 * 1000 ; // 3 seconds for testing
    const item = {
        value: sessionId,
        expiry: expiryTime,
        feedbackSubmitted: false,
    };
    const sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
    sessionData[traceItemId] = item;
    localStorage.setItem("sessionData", JSON.stringify(sessionData));
}

export function getSessionId(traceItemId: string) {
    const sessionData = JSON.parse(localStorage.getItem("sessionData") || "{}");
    const item = sessionData[traceItemId];
    if (!item) {
        return null;
    }
    const now = new Date();
    const currentTime = now.getTime();
    if (currentTime > item.expiry) {
        delete sessionData[traceItemId];
        localStorage.setItem("sessionData", JSON.stringify(sessionData));
        return null;
    }
    return item.value;
}