import { gql } from "@apollo/client";

export const CreateConsumerScan = gql`
mutation CaptureConsumerScan($consumerScanInput: ConsumerScanInput!) {
  captureConsumerScan(consumerScanInput: $consumerScanInput) {
    action
    actionDestination
    authMethod
    authType
    commentsFiled
    createdBy
    endDate
    engagementName
    feedbackMethod
    id
    isActive
    isDeprecated
    orgId
    region {
      engagementImage
      engagementMessage
    }
    submissionDisplayMessage
  }
}
`
export const CaptureConsumerEngagementFeedbacks = gql`
mutation CaptureConsumerEngagementFeedbacks($consumerEngagementFeedbackInput: ConsumerEngagementFeedbackInput!) {
  captureConsumerEngagementFeedbacks(consumerEngagementFeedbackInput: $consumerEngagementFeedbackInput) {
  message  
  }
}
`