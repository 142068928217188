import { useState } from "react";
import { Stack, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { Image } from "mui-image";
import { Certificate } from "../../types";
import CertificatePreview from "../../components/Common/CertificatePreview";
import { ContentSliderProps } from "../../types";


export default function ContentSlider({ orgInfo, customerScanDetails, isExpired, loading }: ContentSliderProps) {
    var settings: {
        dots: boolean;
        infinite: boolean;
        speed: number;
        slidesToShow: number;
        slidesToScroll: number;
        autoplay: boolean,
        autoplaySpeed: number,
        arrows: boolean,
    } = {
        dots: false,
        infinite: isExpired ? false : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: isExpired ? false : true,
        autoplaySpeed: 10000,
        arrows: isExpired ? false : true,
    };

    const [openCertificateModal, setOpenCertificateModal] = useState<boolean>(false);
    const [selectedCertificate, setSelectedCertificate] = useState<Certificate[]>([]);

    const handleOpenCertificate = (certificates: Certificate[]) => {
        setSelectedCertificate(certificates);
        setOpenCertificateModal(true);
    }

    return (
        <>
            <Slider {...settings}>
                <Stack sx={{ direction: { xs: "column", md: "row", alignItems: "center", justifyContent: "center" } }} spacing={2}>
                    <Stack display={"flex"} direction={"row"} justifyContent={"center"}>
                        <Box
                            component="img"
                            src={orgInfo.logoPath}
                            alt=""
                            sx={{ width: { xs: "160px", md: "200px", }, height: "auto", alignSelf: "center", }}
                        />
                    </Stack>
                    <Stack direction="column" flex={1} sx={{ alignItems: "center" }}>
                        <Typography variant="subtitle1" fontWeight={600}>{orgInfo.tagLine}</Typography>
                        <Typography variant="body2" textAlign={"center"} px={2}>{orgInfo.storyLine}</Typography>
                        {
                            orgInfo.certificates.length > 0 && (
                                <Button
                                    onClick={() => handleOpenCertificate(orgInfo.certificates)}
                                    sx={{
                                        color: "#0073e6",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        '&:hover': {
                                            textDecoration: "underline",
                                            color: "#005bb5"
                                        },
                                        fontWeight: 500,
                                    }}
                                >
                                    Our certifications
                                </Button>
                            )
                        }
                    </Stack>
                </Stack>
                {!isExpired && !loading &&
                    <Box>
                        <Stack direction="column" flex={1} sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ maxWidth: "230px" }}>
                                <Image
                                    src={customerScanDetails?.region?.engagementImage}
                                    alt="banner"
                                    xs={{ width: "100px", height: "100%" }}
                                />
                                <Typography variant="body2" textAlign={"center"} mt={1}>{customerScanDetails?.region?.engagementMessage}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                }
            </Slider>
            {openCertificateModal &&
                <CertificatePreview
                    open={openCertificateModal}
                    onClose={() => setOpenCertificateModal(false)}
                    certificates={selectedCertificate}
                />
            }
        </>
    );
}