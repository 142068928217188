import { useState, useRef, useEffect } from "react";
import {
    Dialog, DialogTitle, DialogContent, Typography, Box, useMediaQuery,
    useTheme, Skeleton
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Image } from "mui-image";
import { Certificate, CertificateType, FlattenedCertificate } from "../../types";
import { flattenCertificates } from "../../utils";
import dayjs from "dayjs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type CertificatePreviewInput = {
    open: boolean,
    onClose: (event: any) => void,
    certificates: Certificate[]
}

function CertificatePreview(props: CertificatePreviewInput) {
    const { open, onClose, certificates } = props;

    const flattenedCerts = flattenCertificates(certificates);

    const theme = useTheme();
    const [numPages, setNumPages] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    }, [containerRef.current]);

    const onDocumentLoadSuccess = (data: any, index: number) => {
        setNumPages((prev) => ({
            ...prev,
            [index]: data.numPages,
        }));
    };

    const renderCertificate = (certificate: FlattenedCertificate, index: number) => {
        if (!certificate) return <Typography>No certificate available.</Typography>;

        return (
            <Box key={index} sx={{ marginBottom: 4, width: "100%", textAlign: "center" }} ref={containerRef}>
                {/* Render certificate title */}
                <Typography variant="h6" align="center">
                    {certificate.certificateName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Document Name: {certificate.id}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Uploaded On: {dayjs(certificate.createdEpoch * 1000).format("ddd, DD MMM YYYY h:mm A")}
                </Typography>

                {/* If the file type is an image */}
                {certificate.fileType === CertificateType.IMAGE ? (
                    <Image
                        src={certificate.url}
                        alt="Certificate Preview"
                        style={{ display: "none" }}
                    />
                ) : null}

                {/* If the file type is a PDF */}
                {certificate.fileType === CertificateType.PDF ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'auto', width: '100%' }}>
                        <Document
                            file={certificate.url}
                            onLoadSuccess={(data) => onDocumentLoadSuccess(data, index)}
                            loading={<Skeleton variant="rectangular" width="100%" height={100} />}
                        >
                            {Array.from({ length: numPages[index] || 0 }, (_, pageIndex) => (
                                <Page
                                    key={`page_${pageIndex + 1}`}
                                    pageNumber={pageIndex + 1}
                                    width={containerWidth * 1}
                                />
                            ))}
                        </Document>
                    </Box>
                ) : null}

                {/* Unsupported certificate format */}
                {![CertificateType.IMAGE, CertificateType.PDF].includes(certificate.fileType) && (
                    <Typography>Unsupported certificate format.</Typography>
                )}
            </Box>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" fullScreen={fullScreen}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Our Certifications</Typography>
                    <CancelIcon fontSize="medium" onClick={onClose} style={{ cursor: "pointer" }} />
                </Box>
            </DialogTitle>

            {/* Scrollable Dialog Content */}
            <DialogContent sx={{ height: { xs: '60vh', md: '70vh' }, overflowY: 'auto', padding: 2 }}>
                {flattenedCerts.map((certificate: any, index: number) => renderCertificate(certificate, index))}
            </DialogContent>
        </Dialog>
    );
}

export default CertificatePreview;