import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";

import './index.css';
import App from './App';

import { BrowserRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root")!);

const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_TRACE_URL,
    cache: new InMemoryCache(),
});

root.render(
    <React.StrictMode>
        <Router>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </Router>
    </React.StrictMode>
);
