import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { Image } from "mui-image";
import { ImageCarouselProps } from "../../types";

export default function ImageCarousel({ images }: ImageCarouselProps) {
    var settings: {
        dots: boolean;
        infinite: boolean;
        speed: number;
        slidesToShow: number;
        slidesToScroll: number;
        arrows?: boolean;
        adaptiveHeight: boolean;
    } = {
        dots: true,
        infinite: images.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
    };
    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <Box key={index} sx={{ height: "260px", textAlign: "center" }}>
                    <Image
                        src={image.src}
                        style={{ objectFit: "contain" }}
                        alt="trace"
                    />
                    <Typography variant="caption" color="gray">{image.title}</Typography>
                </Box>
            ))}
        </Slider>
    );
}