import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { DialogModalProps } from '../../types';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};

export default function DialogModal({ open, setOpen, message, title, isIcon = true }: DialogModalProps) {
    const handleClose = () => setOpen(false);

    const linkify = (text) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: "red" }}
                    />
                    {isIcon &&
                        <CheckCircleIcon sx={{ color: 'green', fontSize: 40 }} />}
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2 }}>
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: linkify(message) }}>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}