import pRetry from "p-retry";
import { TraceEventsResponse, FetchOptions, TraceParams } from "./types";

const run = async (url: string, options: FetchOptions) => {
    const response = await fetch(url, options);
    return response.json();
}

const fetchRetry = async (url: string, options: FetchOptions) => {
    return await pRetry(() => run(url, options), {
        retries: 5,
        onFailedAttempt: error => {
            console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
            // 1st request => Attempt 1 failed. There are 4 retries left.
            // 2nd request => Attempt 2 failed. There are 3 retries left.
            // …
        },
    });
}
export const traceEvents = async (traceEventsParams: TraceParams): Promise<TraceEventsResponse> => {
    const { baseUrl, traceItem, orgId, createdEpoch } = traceEventsParams;
    let url = baseUrl + "/traces?trace_item_id=" + traceItem + "&org_id=" + orgId;
    if (createdEpoch) {
        url += "&createdEpoch=" + createdEpoch;
    }
    const options: FetchOptions = {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
    };
    return await fetchRetry(url, options);
}